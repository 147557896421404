import {
  Empty,
  InputNumber,
  Select,
  Spin,
  Table,
  TableProps,
  Tag,
  Tooltip,
} from 'antd';
import dayjs from 'dayjs';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';

import { Server } from '../../../../../../api/server-index';
import { SearchDataSelection } from '../../../../../../types/common/search-data-selection.component';
import { PaginatedData } from '../../../../../../types/dto/common.dto';
import { WorksheetDto } from '../../../../../../types/dto/worksheet.dto';
import { WorkSheetStatus } from '../../../../../../types/enum/worksheet-status';
import { getTagClassName } from '../../../../../../utils/get-tag-class-name';
import { setNavigationPath } from '../../../../../../utils/navigation-params';
import NewButton from '../../../../../common/buttons/new-button.component';
import FilterIcon from '../../../../../common/icons/filter-icon.component';
import MainTitle from '../../../../../common/titles/main-title.component';
import NewWorksheet from '../new-worksheet/new-worksheet.component';

const WorksheetsList = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation('worksheets');

  const [loading, setLoading] = useState<boolean>(true);
  const [reload, setReload] = useState<boolean>(false);
  const [dataSelection, setDataSelection] = useState<SearchDataSelection>({
    page: searchParams.get('page') ? Number(searchParams.get('page')) : 1,
    pageSize: searchParams.get('pageSize')
      ? Number(searchParams.get('pageSize'))
      : 10,
  });
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [worksheets, setWorksheets] = useState<PaginatedData<WorksheetDto[]>>();
  const [filter, setFilter] = useState<{
    status: WorkSheetStatus[];
    worksheetNr?: number;
  }>({
    status: searchParams.get('status')
      ? (searchParams.get('status')?.split(',') || []).map(
          (status) => status as WorkSheetStatus
        )
      : [],
    worksheetNr: searchParams.get('worksheetNr')
      ? Number(searchParams.get('worksheetNr'))
      : undefined,
  });
  const tableRef = useRef<HTMLDivElement | null>(null);

  const fetchWorksheets = useCallback(async () => {
    setLoading(true);
    const response = await Server.Worksheets.getWorksheets({
      page: dataSelection.page,
      pageSize: dataSelection.pageSize,
      status: filter.status,
      worksheetNr: filter.worksheetNr,
    });
    setWorksheets(response);
    setLoading(false);
  }, [dataSelection, filter]);

  useEffect(() => {
    fetchWorksheets();
    setSearchParams(setNavigationPath(filter, dataSelection));
    setReload(false);
  }, [dataSelection, fetchWorksheets, filter, reload, setSearchParams]);

  const columns: TableProps<WorksheetDto>['columns'] = [
    {
      key: 'worksheetNr',
      title: t('list.worksheetNr'),
      dataIndex: 'worksheetNr',
      width: '10%',
      filterDropdown: () => (
        <InputNumber
          placeholder={t('list.worksheetNr')}
          value={filter.worksheetNr}
          min={0}
          className='w-100'
          onChange={(value) => {
            if (value) {
              setFilter((prevData) => ({
                ...prevData,
                worksheetNr: value,
              }));
            } else {
              setFilter((prevData) => ({
                ...prevData,
                worksheetNr: undefined,
              }));
            }
            setDataSelection((prevVal) => ({
              ...prevVal,
              page: 1,
            }));
          }}
        />
      ),
      filterIcon: <FilterIcon count={filter.worksheetNr ? 1 : 0} />,
      render: (number, record) => (
        <Tooltip title={t('edit.title')}>
          <Link to={`edit/${record.id}?${searchParams.toString()}`}>
            {number}
          </Link>
        </Tooltip>
      ),
    },
    {
      key: 'createdDate',
      title: t('list.created_at'),
      dataIndex: 'createdDate',
      width: '20%',
      render: (text) => dayjs(text).format('DD.MM.YYYY. HH:mm'),
    },
    {
      key: 'status',
      title: t('list.status'),
      dataIndex: 'status',
      width: '20%',
      filterDropdown: () => (
        <div className='filter-select'>
          <Select
            allowClear
            value={filter.status}
            mode='multiple'
            placeholder={t('list.status')}
            className='w-100'
            options={Object.values(WorkSheetStatus).map((status) => {
              return {
                key: status,
                value: status,
                label: t(`status.${status}`),
              };
            })}
            onChange={(value) => {
              setFilter((prevData) => ({ ...prevData, status: [...value] }));
              setDataSelection((prevVal) => ({
                ...prevVal,
                page: 1,
              }));
            }}
          />
        </div>
      ),
      filterIcon: <FilterIcon count={filter.status.length ?? 0} />,
      render: (text) => (
        <Tag className={getTagClassName(text as WorkSheetStatus)}>
          {t(`status.${text}`)}
        </Tag>
      ),
    },
    {
      key: 'startDate',
      title: t('list.start_date'),
      dataIndex: 'startDate',
      width: '25%',
      render: (text) => dayjs(text).format('DD.MM.YYYY.'),
    },
    {
      key: 'endDate',
      title: t('list.end_date'),
      dataIndex: 'endDate',
      width: '25%',
      render: (text) => dayjs(text).format('DD.MM.YYYY.'),
    },
  ];

  return (
    <Spin spinning={loading}>
      <div>
        <MainTitle text={t('list.title')} />
        <NewButton onClick={() => setIsModalOpen(true)} />
        <div ref={tableRef}></div>
        <Table
          className='mt-1'
          dataSource={worksheets?.records}
          columns={columns}
          scroll={{ x: 800 }}
          pagination={{
            total: worksheets?.totalCount,
            hideOnSinglePage: true,
            pageSize: dataSelection.pageSize,
            current: dataSelection.page,
            onChange: (page: number, pageSize?: number) => {
              const newPageSize = pageSize || dataSelection.pageSize;
              // Lets get back to first page on size change
              if (newPageSize !== dataSelection.pageSize) page = 1;

              setDataSelection({
                ...dataSelection,
                page,
                pageSize: newPageSize,
              });
              if (tableRef.current) {
                tableRef.current.scrollIntoView({
                  behavior: 'smooth',
                  block: 'start',
                });
              }
            },
          }}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={t('common:no_data')}
              />
            ),
          }}
        />
        <NewWorksheet
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          setReload={setReload}
        />
      </div>
    </Spin>
  );
};

export default WorksheetsList;
