export enum WorkSheetStatus {
  DRAFT = 'DRAFT',
  IN_REVIEW_BY_PROGRAMME_COORDINATOR = 'IN_REVIEW_BY_PROGRAMME_COORDINATOR',
  APPROVED_BY_PROGRAMME_COORDINATOR = 'APPROVED_BY_PROGRAMME_COORDINATOR',
  REJECTED_BY_PROGRAMME_COORDINATOR = 'REJECTED_BY_PROGRAMME_COORDINATOR',
  IN_REVIEW_BY_ACCOUNTING = 'IN_REVIEW_BY_ACCOUNTING',
  APPROVED_BY_ACCOUNTING = 'APPROVED_BY_ACCOUNTING',
  REJECTED_BY_ACCOUNTING = 'REJECTED_BY_ACCOUNTING',
  PAYMENT_COMPLETED = 'PAYMENT_COMPLETED',
}
