import {
  Button,
  Divider,
  Flex,
  Popconfirm,
  Spin,
  Tabs,
  Tooltip,
  notification,
} from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Server } from '../../../../../../api/server-index';
import { WorksheetDto } from '../../../../../../types/dto/worksheet.dto';
import { WorkSheetStatus } from '../../../../../../types/enum/worksheet-status';
import BackButton from '../../../../../common/buttons/back-button.component';
import WorksheetDetails from '../worksheet-details/worksheet-details.component';
import OtherExpenses from './other-expenses/other-expenses.component';
import WorksheetEvents from './worksheet-events/worksheet-events.component';
import WorksheetMeta from './worksheet-meta/worksheet-meta.component';
import WorksheetNotes from './worksheet-notes/worksheet-notes.component';

const EditWorksheet = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const worksheetId = params.worksheetId;
  const { t } = useTranslation('worksheets');
  const [loading, setLoading] = useState<boolean>(true);
  const [reload, setReload] = useState<boolean>(false);
  const [worksheet, setWorksheet] = useState<WorksheetDto>();
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);

  const fetchWorksheet = useCallback(async () => {
    setLoading(true);

    if (worksheetId) {
      const worksheetRes = await Server.Worksheets.getWorksheet(worksheetId);
      setWorksheet(worksheetRes);
    } else {
      notification.error({ message: t('edit.not_available') });
      navigate(-1);
    }

    setLoading(false);
  }, [navigate, t, worksheetId]);

  useEffect(() => {
    fetchWorksheet();
    setReload(false);
  }, [fetchWorksheet, reload]);

  const deleteWorksheet = async () => {
    setLoading(true);

    if (worksheetId) {
      const response = await Server.Worksheets.deleteWorksheet(worksheetId);
      if (response.status === 200) {
        notification.success({ message: t('edit.actions.delete.success') });
        navigate(-1);
      }
    }

    setLoading(false);
  };

  const withdrawWorksheet = async () => {
    setLoading(true);

    if (worksheetId) {
      const response = await Server.Worksheets.withdrawWorksheet(worksheetId);

      if (response.status === 200) {
        notification.success({ message: t('edit.actions.withdraw.success') });
        setReload(true);
      }
    }

    setLoading(false);
  };

  const rescanWorksheet = async () => {
    setLoading(true);

    if (worksheetId) {
      const response = await Server.Worksheets.rescanWorksheet(worksheetId);
      if (response.status === 200) {
        notification.success({ message: t('edit.actions.rescan.success') });
        setReload(true);
      }
    }

    setLoading(false);
  };

  const submitWorksheet = async () => {
    setLoading(true);

    if (worksheetId) {
      const response = await Server.Worksheets.submitWorksheet(worksheetId);
      if (response.status === 200) {
        notification.success({ message: t('edit.actions.submit.success') });
        navigate(-1);
      }
    }

    setLoading(false);
  };

  return (
    <Spin spinning={loading}>
      <BackButton href={`/worksheets${location?.search}`} />
      <Flex vertical>
        <WorksheetDetails worksheet={worksheet} />

        <Flex justify='end' className='ws-info'>
          {worksheet?.status !== WorkSheetStatus.APPROVED_BY_ACCOUNTING ? (
            <Flex gap={12} className='mt-1'>
              <Popconfirm
                title={
                  worksheet?.status === WorkSheetStatus.DRAFT
                    ? t('edit.popconfirm.delete')
                    : t('edit.popconfirm.withdraw')
                }
                onConfirm={() => {
                  worksheet?.status === WorkSheetStatus.DRAFT
                    ? deleteWorksheet()
                    : withdrawWorksheet();
                }}
                okText={t('edit.popconfirm.confirm')}
                cancelText={t('edit.popconfirm.cancel')}
              >
                <Button loading={loading} danger ghost>
                  {worksheet?.status === WorkSheetStatus.DRAFT
                    ? t('edit.actions.delete.title')
                    : t('edit.actions.withdraw.title')}
                </Button>
              </Popconfirm>
              {worksheet?.status === WorkSheetStatus.DRAFT ? (
                <>
                  <Tooltip title={t('edit.actions.rescan.tooltip')}>
                    <Button loading={loading} onClick={() => rescanWorksheet()}>
                      {t('edit.actions.rescan.title')}
                    </Button>
                  </Tooltip>
                  <Tooltip
                    title={
                      submitDisabled
                        ? t('edit.actions.submit.not_able')
                        : t('edit.actions.submit.tooltip')
                    }
                  >
                    <Button
                      loading={loading}
                      disabled={submitDisabled}
                      onClick={() => submitWorksheet()}
                      type='primary'
                      ghost
                    >
                      {t('edit.actions.submit.title')}
                    </Button>
                  </Tooltip>
                </>
              ) : (
                <></>
              )}
            </Flex>
          ) : (
            <></>
          )}
        </Flex>
      </Flex>
      <Divider />
      {worksheet ? (
        <Tabs
          defaultActiveKey='events_and_expenses'
          destroyInactiveTabPane
          items={[
            {
              key: 'events_and_expenses',
              label: t('edit.events.tab_title'),
              children: (
                <>
                  <WorksheetEvents
                    parentReload={reload}
                    worksheet={worksheet}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <OtherExpenses worksheet={worksheet} />
                </>
              ),
            },
            {
              key: 'notes',
              label: t('edit.notes.title'),
              children: <WorksheetNotes worksheet={worksheet} />,
            },
            {
              key: 'meta',
              label: t('meta.title'),
              children: <WorksheetMeta />,
            },
          ]}
        />
      ) : (
        <></>
      )}
    </Spin>
  );
};

export default EditWorksheet;
