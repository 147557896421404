import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import {
  Button,
  Empty,
  Flex,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Table,
  TablePaginationConfig,
  TableProps,
  Tooltip,
  Upload,
  notification,
} from 'antd';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Server } from '../../../../../../../api/server-index';
import { PaginatedData } from '../../../../../../../types/dto/common.dto';
import {
  CreateUpdateOtherExpenseDto,
  OtherExpenseAttachmentDto,
  OtherExpensesDto,
  WorksheetDto,
} from '../../../../../../../types/dto/worksheet.dto';
import { WorkSheetStatus } from '../../../../../../../types/enum/worksheet-status';
import NewButton from '../../../../../../common/buttons/new-button.component';

interface OtherExpensesProps {
  worksheet: WorksheetDto;
}

const OtherExpenses = (props: OtherExpensesProps) => {
  const { worksheet } = props;
  const { t } = useTranslation('worksheets');
  const [reload, setReload] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isNewExpenseModalOpen, setIsNewExpenseModalOpen] =
    useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [otherExpenses, setOtherExpenses] =
    useState<PaginatedData<OtherExpensesDto[]>>();
  const [newExpense, setNewExpense] = useState<CreateUpdateOtherExpenseDto>({
    title: '',
    totalAmount: 0,
  });
  const [editingExpenseId, setEditingExpenseId] = useState<string>();
  const [editData, setEditData] = useState<CreateUpdateOtherExpenseDto>();
  const tableRef = useRef<HTMLDivElement | null>(null);

  const fetchOtherExpenses = useCallback(async () => {
    setLoading(true);
    const response = await Server.Worksheets.getOtherExpenses({
      page: currentPage,
      pageSize: 10,
      worksheetId: worksheet.id,
    });
    setOtherExpenses(response);
    setLoading(false);
  }, [currentPage, worksheet.id]);

  useEffect(() => {
    fetchOtherExpenses();
    setReload(false);
  }, [fetchOtherExpenses, reload]);

  const createNewExpense = async () => {
    setLoading(true);

    const response = await Server.Worksheets.createOtherExpense(
      worksheet.id,
      newExpense
    );
    if (response.status === 201) {
      notification.success({ message: t('edit.expenses.actions.new.success') });
      setIsNewExpenseModalOpen(false);
      setNewExpense({ title: '', totalAmount: 0 });
      setReload(true);
    }

    setLoading(false);
  };

  const deleteExpense = async (id: string) => {
    setLoading(true);

    const response = await Server.Worksheets.deleteOtherExpense(id);
    if (response.status === 200) {
      notification.success({
        message: t('edit.expenses.actions.success_delete'),
      });
      setReload(true);
    }

    setLoading(false);
  };

  const updateExpense = async (id: string) => {
    setLoading(true);

    if (editData) {
      const response = await Server.Worksheets.updateOtherExpense(id, editData);
      if (response.status === 200) {
        notification.success({
          message: t('edit.expenses.actions.success_update'),
        });
        setEditingExpenseId('');
        setReload(true);
      }
    }

    setLoading(false);
  };

  const uploadAttachment = async (id: string, file: any) => {
    setLoading(true);

    const response = await Server.Worksheets.createOtherExpenseAttachment(
      id,
      file
    );
    if (response.status === 201) {
      notification.success({
        message: t('edit.expenses.actions.success_att_upload'),
      });
      setReload(true);
    }

    setLoading(false);
  };

  const deleteAttachment = async (id: string) => {
    setLoading(true);

    const response = await Server.Worksheets.deleteOtherExpenseAttachment(id);
    if (response.status === 200) {
      notification.success({
        message: t('edit.expenses.actions.success_att_delete'),
      });
      setReload(true);
    }

    setLoading(false);
  };

  const downloadAttachment = async (binary: OtherExpenseAttachmentDto) => {
    setLoading(true);
    try {
      const binaryData = await Server.Worksheets.getOtherExpenseAttachment(
        binary.id
      );

      const blob = new Blob([binaryData], { type: 'application/octet-stream' });

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = binary.fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {}

    setLoading(false);
  };

  const columns: TableProps<OtherExpensesDto>['columns'] = [
    {
      key: 'title',
      title: t('edit.expenses.expense_title'),
      dataIndex: 'title',
      width: '40%',
      render: (title) => <span>{title}</span>,
    },
    {
      key: 'attachments',
      title: t('edit.expenses.attachments'),
      dataIndex: 'binaries',
      width: '40%',
      render: (binaries: OtherExpenseAttachmentDto[], record) => {
        if (editingExpenseId === record.id) {
          return (
            <>
              <Upload
                name='file'
                customRequest={(file) => uploadAttachment(record.id, file)}
                showUploadList={false}
              >
                <Button icon={<UploadOutlined />}>
                  {t('edit.expenses.actions.upload')}
                </Button>
              </Upload>
              {binaries.map((binary) => (
                <Flex key={binary.id} gap={12} className='mt-1'>
                  <span>{binary.fileName}</span>

                  <DeleteOutlined onClick={() => deleteAttachment(binary.id)} />
                </Flex>
              ))}
            </>
          );
        }
        return binaries.map((binary) => (
          <Flex key={binary.id} gap={12}>
            <span>{binary.fileName}</span>
            <DownloadOutlined onClick={() => downloadAttachment(binary)} />
          </Flex>
        ));
      },
    },
    {
      key: 'totalAmount',
      title: t('edit.expenses.total_amount'),
      dataIndex: 'totalAmount',
      width: '20%',
      render: (amount, record) => {
        if (editingExpenseId === record.id) {
          return (
            <InputNumber
              decimalSeparator=','
              addonAfter={'€'}
              placeholder={t('edit.expenses.expense_title')}
              defaultValue={record.totalAmount}
              min={0}
              onChange={(value) => {
                if (value) {
                  setEditData({
                    title: record.title,
                    totalAmount: value,
                  });
                }
              }}
            />
          );
        }
        return <span>{amount.toFixed(2).replace('.', ',')} €</span>;
      },
    },
  ];

  if (worksheet.status === WorkSheetStatus.DRAFT) {
    columns.push({
      key: 'actions',
      title: t('edit.expenses.actions.title'),
      dataIndex: 'id',
      width: '20%',
      render: (id) => (
        <Flex gap={12}>
          {editingExpenseId === id ? (
            <>
              <Tooltip title={t('edit.expenses.actions.popconfirm.cancel')}>
                <Button
                  onClick={() => {
                    setEditingExpenseId('');
                  }}
                >
                  <CloseOutlined />
                </Button>
              </Tooltip>

              <Tooltip title={t('edit.expenses.actions.popconfirm.ok')}>
                <Button
                  loading={loading}
                  onClick={() => {
                    updateExpense(id);
                    setEditingExpenseId('');
                  }}
                >
                  <CheckOutlined />
                </Button>
              </Tooltip>
            </>
          ) : (
            <Tooltip title={t('edit.expenses.actions.edit')}>
              <Button loading={loading} onClick={() => setEditingExpenseId(id)}>
                <EditOutlined />
              </Button>
            </Tooltip>
          )}

          <Popconfirm
            title={t('edit.expenses.actions.popconfirm.title')}
            onConfirm={() => deleteExpense(id)}
            okText={t('edit.expenses.actions.popconfirm.ok')}
            cancelText={t('edit.expenses.actions.popconfirm.cancel')}
          >
            <Button loading={loading} danger>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </Flex>
      ),
    });
  }

  const handlePageChange = (pagination: TablePaginationConfig) => {
    setCurrentPage(pagination.current || 1);
    if (tableRef.current) {
      tableRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  return (
    <>
      <Flex gap={12} align='center'>
        <h4>{t('edit.expenses.title')}</h4>
        {worksheet.status === WorkSheetStatus.DRAFT ? (
          <Tooltip title={t('edit.expenses.actions.new.title')}>
            <NewButton onClick={() => setIsNewExpenseModalOpen(true)} />
          </Tooltip>
        ) : (
          <></>
        )}
      </Flex>
      <div ref={tableRef}></div>
      <Table
        loading={loading}
        dataSource={otherExpenses?.records}
        columns={columns}
        scroll={{ x: 800 }}
        pagination={{
          total: otherExpenses?.totalCount,
          pageSize: 10,
          hideOnSinglePage: true,
        }}
        onChange={handlePageChange}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('common:no_data')}
            />
          ),
        }}
      />
      <Modal
        open={isNewExpenseModalOpen}
        onCancel={() => setIsNewExpenseModalOpen(false)}
        onOk={() => createNewExpense()}
        okButtonProps={{ ghost: true, disabled: newExpense.title.length === 0 }}
        okText={t('edit.expenses.actions.new.confirm')}
        cancelText={t('edit.expenses.actions.new.cancel')}
        confirmLoading={loading}
      >
        <h3>{t('edit.expenses.actions.new.title')}</h3>
        <Flex vertical gap={12}>
          <Input
            value={newExpense.title}
            placeholder={t('edit.expenses.expense_title')}
            onChange={(e) =>
              setNewExpense((prevData) => ({
                ...prevData,
                title: e.target.value,
              }))
            }
          />
          <InputNumber
            decimalSeparator=','
            addonAfter={'€'}
            value={newExpense.totalAmount}
            placeholder={t('edit.expenses.total_amount')}
            min={0}
            style={{ width: '40%' }}
            onChange={(value) => {
              if (value) {
                setNewExpense((prevData) => ({
                  ...prevData,
                  totalAmount: value,
                }));
              }
            }}
          />
        </Flex>
      </Modal>
    </>
  );
};

export default OtherExpenses;
