import { Alert, Button, Flex, Form, Grid, Input } from 'antd';
import { Dispatch, SetStateAction, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { AuthContext } from '../../../context/auth-context';

interface SecurityPinFormProps {
  setAuthorized: Dispatch<SetStateAction<boolean>>;
}

const SecurityPinForm = (props: SecurityPinFormProps) => {
  const { checkSecurityPin, securityPin } = useContext(AuthContext);
  const { t } = useTranslation('settings');
  const sizing = Grid.useBreakpoint();

  return (
    <div className='min-vh-70 d-flex justify-content-center align-items-center'>
      <div className='login'>
        <Form onFinish={props.setAuthorized}>
          {!securityPin ? (
            <>
              <Alert
                showIcon
                type='warning'
                description={
                  <Flex gap={8} align='center'>
                    <span>
                      {t('security_pin.enter_pin.alert.title')}
                      <Link to={'/settings'}>
                        {t('security_pin.enter_pin.alert.settings')}
                      </Link>
                      .
                    </span>
                  </Flex>
                }
              />
            </>
          ) : (
            <Flex vertical align='center'>
              <h3 className='text-white'>
                {t('security_pin.enter_pin.title')}
              </h3>
              <Form.Item
                name={'pin'}
                rules={[
                  () => ({
                    validator(_, value) {
                      if (!value || checkSecurityPin(value)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(t('security_pin.enter_pin.invalid'))
                      );
                    },
                  }),
                ]}
                validateTrigger='onSubmit'
                wrapperCol={sizing.xs === true ? undefined : { offset: 0 }}
              >
                <Input.Password
                  disabled={!securityPin}
                  autoComplete='off'
                  placeholder={t('security_pin.enter_pin.pin')}
                />
              </Form.Item>
              <Form.Item
                wrapperCol={sizing.xs === true ? undefined : { offset: 0 }}
              >
                <Button htmlType='submit' className='logout-button'>
                  {t('security_pin.enter_pin.submit')}
                </Button>
              </Form.Item>
            </Flex>
          )}
        </Form>
      </div>
    </div>
  );
};

export default SecurityPinForm;
