import { DeleteOutlined, InfoOutlined } from '@ant-design/icons';
import {
  Button,
  Empty,
  Flex,
  notification,
  Popconfirm,
  Select,
  Spin,
  Table,
  TableProps,
  Tag,
  Tooltip,
} from 'antd';
import dayjs from 'dayjs';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Server } from '../../../../../../api/server-index';
import { SearchDataSelection } from '../../../../../../types/common/search-data-selection.component';
import { PaginatedData } from '../../../../../../types/dto/common.dto';
import { BusinessTripRequestDto } from '../../../../../../types/dto/work-logs.dto';
import { RequestStatus } from '../../../../../../types/enum/request-status';
import { getTagClassName } from '../../../../../../utils/get-tag-class-name';
import { setNavigationPath } from '../../../../../../utils/navigation-params';
import NewButton from '../../../../../common/buttons/new-button.component';
import FilterIcon from '../../../../../common/icons/filter-icon.component';
import BusinessTripRequestDetails from './business-trip-request-details/business-trip-request-details.component';
import NewBusinessTripRequest from './new-business-trip-request/new-business-trip-request.component';

const BusinessTripRequests = () => {
  const { t } = useTranslation('work-logs');
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [reload, setReload] = useState<boolean>(false);
  const [dataSelection, setDataSelection] = useState<SearchDataSelection>({
    page: searchParams.get('page') ? Number(searchParams.get('page')) : 1,
    pageSize: searchParams.get('pageSize')
      ? Number(searchParams.get('pageSize'))
      : 10,
  });
  const [businessTripRequests, setBusinessTripRequests] =
    useState<PaginatedData<BusinessTripRequestDto[]>>();
  const [filter, setFilter] = useState<{ status: RequestStatus[] }>({
    status: searchParams.get('status')
      ? (searchParams.get('status')?.split(',') || []).map(
          (status) => status as RequestStatus
        )
      : [],
  });
  const [detailsRequest, setDetailsRequest] =
    useState<BusinessTripRequestDto>();
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState<boolean>(false);
  const [isNewModalOpen, setIsNewModalOpen] = useState<boolean>(false);
  const tableRef = useRef<HTMLDivElement | null>(null);

  const fetchBusinessTripRequests = useCallback(async () => {
    setLoading(true);
    const response = await Server.BusinessTripRequests.getBusinessTripRequests({
      page: dataSelection.page,
      pageSize: dataSelection.pageSize,
      status: filter.status.length > 0 ? filter.status : undefined,
    });

    setBusinessTripRequests(response);
    setLoading(false);
  }, [dataSelection, filter]);

  useEffect(() => {
    fetchBusinessTripRequests();
    setSearchParams(setNavigationPath(filter, dataSelection));
    setReload(false);
  }, [
    dataSelection,
    fetchBusinessTripRequests,
    filter,
    setSearchParams,
    reload,
  ]);

  const handleDeleteRequest = async (id: string) => {
    try {
      const response =
        await Server.BusinessTripRequests.deleteBusinessTripRequest(id);

      if (response.status === 200) {
        notification.success({ message: t('btr.delete.success') });
        setReload(true);
      }
    } catch (error) {
      return error;
    }
  };

  const columns: TableProps<BusinessTripRequestDto>['columns'] = [
    {
      key: 'tripStart',
      title: t('btr.trip_start'),
      dataIndex: 'tripStart',
      width: '20%',
      render: (tripStart) => (
        <Flex gap={6}>
          <span>{dayjs(tripStart).format('DD.MM.YYYY.')}</span>
          <span className='text-gray'>{dayjs(tripStart).format('HH:mm')}</span>
        </Flex>
      ),
    },
    {
      key: 'tripEnd',
      title: t('btr.trip_end'),
      dataIndex: 'tripEnd',
      width: '20%',
      render: (tripEnd) => (
        <Flex gap={6}>
          <span>{dayjs(tripEnd).format('DD.MM.YYYY.')}</span>
          <span className='text-gray'>{dayjs(tripEnd).format('HH:mm')}</span>
        </Flex>
      ),
    },
    {
      key: 'destination',
      title: t('btr.destination'),
      dataIndex: 'destination',
      width: '20%',
      render: (destination) => <span>{destination}</span>,
    },
    {
      key: 'status',
      title: t('btr.status.title'),
      dataIndex: 'status',
      width: '20%',
      filterDropdown: () => (
        <Select
          className='filter-select'
          placeholder={t('btr.status.title')}
          mode='multiple'
          options={Object.values(RequestStatus).map((s) => ({
            value: s,
            label: t(`btr.status.${s}`),
          }))}
          onChange={(value) => {
            setFilter((prevData) => ({ ...prevData, status: [...value] }));
            setDataSelection((prevVal) => ({
              ...prevVal,
              page: 1,
            }));
          }}
        />
      ),
      filterIcon: <FilterIcon count={filter.status.length ?? 0} />,
      render: (status) => (
        <Tag className={getTagClassName(status)}>
          {t(`btr.status.${status}`)}
        </Tag>
      ),
    },
    {
      key: 'options',
      title: t('btr.options.title'),
      dataIndex: 'id',
      width: '20%',
      render: (id, record) => (
        <Flex gap={8}>
          <Tooltip title={t('btr.options.info')}>
            <Button
              onClick={() => {
                setDetailsRequest(record);
                setIsDetailsModalOpen(true);
              }}
            >
              <InfoOutlined />
            </Button>
          </Tooltip>
          <Popconfirm
            title={t('btr.options.delete')}
            onConfirm={() => handleDeleteRequest(id)}
          >
            <Button danger>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </Flex>
      ),
    },
  ];

  return (
    <Spin spinning={loading}>
      <div ref={tableRef}></div>
      <NewButton onClick={() => setIsNewModalOpen(true)} />
      <Table
        rowKey={(val) => val.id}
        columns={columns}
        className='mt-1'
        dataSource={businessTripRequests?.records}
        scroll={{ x: 800 }}
        pagination={{
          total: businessTripRequests?.totalCount,
          hideOnSinglePage: true,
          pageSize: dataSelection.pageSize,
          current: dataSelection.page,
          onChange: (page: number, pageSize?: number) => {
            const newPageSize = pageSize || dataSelection.pageSize;
            // Lets get back to first page on size change
            if (newPageSize !== dataSelection.pageSize) page = 1;

            setDataSelection({
              ...dataSelection,
              page,
              pageSize: newPageSize,
            });
            if (tableRef.current) {
              tableRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
              });
            }
          },
        }}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('common:no_data')}
            />
          ),
        }}
      />
      {detailsRequest ? (
        <BusinessTripRequestDetails
          isDetailsModalOpen={isDetailsModalOpen}
          setIsDetailsModalOpen={setIsDetailsModalOpen}
          detailsRequest={detailsRequest}
          setDetailsRequest={setDetailsRequest}
        />
      ) : (
        <></>
      )}
      <NewBusinessTripRequest
        isNewModalOpen={isNewModalOpen}
        setIsNewModalOpen={setIsNewModalOpen}
        setReload={setReload}
      />
    </Spin>
  );
};

export default BusinessTripRequests;
