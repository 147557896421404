import { Col, Empty, Flex, Input, Row, Spin, Table, TableProps } from 'antd';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';

import { Server } from '../../../../../../../api/server-index';
import { AuthContext } from '../../../../../../../context/auth-context';
import { SearchDataSelection } from '../../../../../../../types/common/search-data-selection.component';
import { PaginatedData } from '../../../../../../../types/dto/common.dto';
import { SubjectDto } from '../../../../../../../types/dto/subject.dto';
import { UserDto } from '../../../../../../../types/dto/user.dto';
import { setNavigationPath } from '../../../../../../../utils/navigation-params';
import BackButton from '../../../../../../common/buttons/back-button.component';
import Subtitle from '../../../../../../common/titles/subtitle.component';

const SubjectStudents = () => {
  const params = useParams();
  const { currentAcademicYear } = useContext(AuthContext);
  const { t } = useTranslation('subjects');
  const [searchParams, setSearchParams] = useSearchParams();
  const [subject, setSubject] = useState<SubjectDto>();
  const [students, setStudents] = useState<PaginatedData<UserDto[]>>();
  const [loading, setLoading] = useState<boolean>(false);
  const [dataSelection, setDataSelection] = useState<SearchDataSelection>({
    page: searchParams.get('page') ? Number(searchParams.get('page')) : 1,
    pageSize: searchParams.get('pageSize')
      ? Number(searchParams.get('pageSize'))
      : 10,
    search: searchParams.get('search') ?? '',
  });
  const tableRef = useRef<HTMLDivElement | null>(null);

  const fetchSubject = useCallback(async () => {
    if (params.subjectId) {
      setLoading(true);
      const response = await Server.Subjects.getSubjectById(params.subjectId);
      setSubject(response);
    }
  }, [params.subjectId]);

  const fetchStudents = useCallback(async () => {
    if (params.subjectId) {
      setLoading(true);
      const response = await Server.User.getStudentsInSubject({
        page: dataSelection.page,
        pageSize: dataSelection.pageSize,
        search: dataSelection.search,
        subjectId: params.subjectId,
        academicYear: currentAcademicYear,
      });
      setStudents(response);
      setLoading(false);
    }
  }, [currentAcademicYear, dataSelection, params.subjectId]);

  useEffect(() => {
    fetchSubject();
    fetchStudents();
    setSearchParams(setNavigationPath({}, dataSelection));
  }, [dataSelection, fetchSubject, fetchStudents, setSearchParams]);

  const columns: TableProps<UserDto>['columns'] = [
    {
      key: 'pin',
      title: 'OIB',
      dataIndex: 'pin',
      width: '15%',
      render: (text) => <span>{text}</span>,
    },
    {
      key: 'hrEduUid',
      title: 'JMBAG',
      dataIndex: 'hrEduUid',
      width: '15%',
      render: (text) => <span>{text}</span>,
    },
    {
      key: 'givenName',
      title: 'Ime',
      dataIndex: 'givenName',
      width: '20%',
      render: (text) => <span>{text}</span>,
    },
    {
      key: 'familyName',
      title: 'Prezime',
      dataIndex: 'familyName',
      width: '20%',
      render: (text) => <span>{text}</span>,
    },
    {
      key: 'email',
      title: 'E-pošta',
      dataIndex: 'email',
      render: (text) => <span>{text}</span>,
    },
  ];

  return (
    <Spin spinning={loading}>
      <BackButton href={`/subjects`} />
      <Flex gap={4} className='mobile-vertical'>
        <Subtitle text={`${t('students.title')}`} />
        {subject ? (
          <i>
            <Subtitle
              text={`${subject.subjectName} (${currentAcademicYear}/${
                currentAcademicYear + 1
              })`}
            />
          </i>
        ) : (
          <></>
        )}
      </Flex>
      <div ref={tableRef}></div>
      <Row>
        <Col xs={24} sm={24} md={18} lg={8} xl={8}>
          <Input
            placeholder={t('students.search')}
            value={dataSelection.search}
            onChange={(event) => {
              setDataSelection((prevVal) => ({
                ...prevVal,
                page: 1,
                search: event.target.value,
              }));
            }}
          />
        </Col>
      </Row>
      <Table
        className='mt-1'
        dataSource={students?.records}
        columns={columns}
        rowKey={(val) => val.pin}
        pagination={{
          hideOnSinglePage: true,
          total: students?.totalCount,
          onChange: (page: number, pageSize?: number) => {
            const newPageSize = pageSize || dataSelection.pageSize;
            // Lets get back to first page on size change
            if (newPageSize !== dataSelection.pageSize) page = 1;

            setDataSelection({
              ...dataSelection,
              page,
              pageSize: newPageSize,
            });
            if (tableRef.current) {
              tableRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
              });
            }
          },
        }}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('common:no_data')}
            />
          ),
        }}
      />
    </Spin>
  );
};

export default SubjectStudents;
