import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Select,
  Table,
  TableProps,
  Flex,
  Popconfirm,
  Button,
  notification,
  Tag,
  Tooltip,
  Empty,
  InputNumber,
} from 'antd';
import dayjs from 'dayjs';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Server } from '../../../../../../../api/server-index';
import {
  WorksheetDto,
  WorksheetEventDto,
  WorksheetEventUpdateDto,
} from '../../../../../../../types/dto/worksheet.dto';
import { EventType } from '../../../../../../../types/enum/event-type';
import { WorkSheetStatus } from '../../../../../../../types/enum/worksheet-status';

interface WorksheetEventsProps {
  parentReload: boolean;
  worksheet: WorksheetDto;
  setSubmitDisabled: Dispatch<SetStateAction<boolean>>;
}

const WorksheetEvents = (props: WorksheetEventsProps) => {
  const { worksheet } = props;
  const { t } = useTranslation('worksheets');
  const [reload, setReload] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [events, setEvents] = useState<WorksheetEventDto[]>([]);
  const [editingEventId, setEditingEventId] = useState<string>();
  const [editData, setEditData] = useState<WorksheetEventUpdateDto>();
  const tableRef = useRef<HTMLDivElement | null>(null);

  const checkIfAbleToSubmit = useCallback(
    (wsEvents: WorksheetEventDto[]) => {
      const unfinishedEvent = wsEvents.some(
        (event) => !event.overlapEvent && event.eventType === null
      );

      if (unfinishedEvent) {
        props.setSubmitDisabled(true);
      } else {
        props.setSubmitDisabled(false);
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const fetchWorksheetEvents = useCallback(async () => {
    setLoading(true);
    const response = await Server.Worksheets.getWorksheetEvents({
      page: 1,
      pageSize: 100,
      worksheetId: worksheet.id,
    });

    const sortedEvents = response.records.sort((a, b) => {
      const aDate = dayjs(a.dateStart).format('YYYY.MM.DD/HH:mm');
      const bDate = dayjs(b.dateStart).format('YYYY.MM.DD/HH:mm');

      if (aDate !== bDate) {
        return aDate.localeCompare(bDate);
      }
      return a.overlapEvent === b.overlapEvent ? 0 : a.overlapEvent ? 1 : -1;
    });

    setEvents(sortedEvents);
    checkIfAbleToSubmit(sortedEvents);
    setLoading(false);
  }, [checkIfAbleToSubmit, worksheet.id]);

  useEffect(() => {
    fetchWorksheetEvents();
    setReload(false);
  }, [fetchWorksheetEvents, reload, props.parentReload]);

  const updateEvent = async (wsEventId: string) => {
    setLoading(true);

    if (editData) {
      if (Number.isInteger(editData.duration)) {
        editData.duration = Number(editData.duration?.toFixed(0));
      }

      const response = await Server.Worksheets.updateWorksheetEvent(
        wsEventId,
        editData
      );
      if (response.status === 200) {
        notification.success({
          message: t('edit.events.options.success_update'),
        });
        setEditingEventId('');
        setReload(true);
      }
    }

    setEditData({ duration: undefined, eventType: undefined });
    setLoading(false);
    checkIfAbleToSubmit(events);
  };

  const splitEvent = async (wsEventId: string) => {
    setLoading(true);

    const response = await Server.Worksheets.splitWorksheetEvent(wsEventId);
    if (response.status === 200) {
      notification.success({ message: t('edit.events.options.success_split') });
      setReload(true);
    }

    setLoading(false);
    checkIfAbleToSubmit(events);
  };

  const deleteEvent = async (wsEventId: string) => {
    setLoading(true);

    const response = await Server.Worksheets.deleteWorksheetEvent(wsEventId);
    if (response.status === 200) {
      notification.success({
        message: t('edit.events.options.success_delete'),
      });
      setReload(true);
    }

    setLoading(false);
  };

  const getEventTypeOptions = (record: WorksheetEventDto) => {
    if (record.splitEvent) {
      const unavailableTypes: EventType[] = [];
      const relatedWsEvents = events.filter(
        (event) => event.eventId === record.eventId
      );

      for (const relatedWsEvent of relatedWsEvents) {
        if (relatedWsEvent.eventType) {
          unavailableTypes.push(relatedWsEvent.eventType);
        }
      }

      return Object.values(EventType)
        .filter((type) => !unavailableTypes.includes(type))
        .map((type) => ({
          key: type,
          value: type,
          label: t(`edit.events.event_type.${type}`),
        }));
    } else
      return Object.values(EventType).map((type) => ({
        key: type,
        value: type,
        label: t(`edit.events.event_type.${type}`),
      }));
  };

  const eventsColumns: TableProps<WorksheetEventDto>['columns'] = [
    {
      key: 'subjectName',
      title: t('edit.events.subject_name'),
      dataIndex: 'event',
      width: '20%',
      render: (event, record) => {
        let tooltip = '';
        if (record.overlapEvent) {
          tooltip = t('edit.events.double_tooltip');
        }
        if (record.splitEvent) {
          tooltip = t('edit.events.split_tooltip');
        }

        return (
          <Flex vertical>
            <Tooltip title={tooltip}>
              <span>{event.subjectName}</span>
            </Tooltip>
            {record.note ? (
              <small className='text-gray'>{record.note}</small>
            ) : (
              <></>
            )}
          </Flex>
        );
      },
    },
    {
      key: 'date',
      title: t('edit.events.duration'),
      dataIndex: 'event',
      width: '25%',
      render: (event, record) => {
        return record.dateStart && record.dateEnd ? (
          <Flex vertical>
            <span>{`${dayjs(record.dateStart).format('DD.MM.YYYY.')}`}</span>
            <span className='text-gray'>{`${dayjs(record.dateStart).format(
              'HH:mm'
            )} - ${dayjs(record.dateEnd).format('HH:mm')}`}</span>
          </Flex>
        ) : (
          '---'
        );
      },
    },
    {
      key: 'hours',
      title: t('edit.events.hours'),
      dataIndex: 'duration',
      width: '15%',
      render: (value, record) => {
        if (editingEventId === record.id) {
          return (
            <InputNumber
              decimalSeparator=','
              addonAfter={'h'}
              min={0}
              step={0.5}
              onChange={(value) => {
                if (value) {
                  setEditData((prevData) => ({ ...prevData, duration: value }));
                }
              }}
            />
          );
        }

        return record.duration ? <span>{value}</span> : '---';
      },
    },
    {
      key: 'eventType',
      title: t('edit.events.event_type.title'),
      dataIndex: 'eventType',
      width: '20%',
      render: (text, record) => {
        if (editingEventId === record.id) {
          return (
            <Select
              options={getEventTypeOptions(record)}
              placeholder={t('edit.events.event_type.title')}
              value={editData?.eventType ?? null}
              className='w-100'
              onChange={(value) =>
                setEditData((prevData) => ({ ...prevData, eventType: value }))
              }
            />
          );
        }

        return (
          <span>
            {text ? <Tag>{t(`edit.events.event_type.${text}`)}</Tag> : '---'}
          </span>
        );
      },
    },
    {
      key: 'attendance',
      title: t('edit.events.attendance'),
      dataIndex: 'attendance',
      width: '5%',
      render: (text) => <span>{text}</span>,
    },
    {
      key: 'totalAmount',
      title: t('edit.events.total_amount'),
      dataIndex: 'totalAmount',
      width: '10%',
      render: (value, record) => {
        if (!record.overlapEvent) {
          return (
            <span>{`${value ? value.toFixed(2).replace('.', ',') : 0} €`}</span>
          );
        } else return '---';
      },
    },
  ];

  if (worksheet.status === WorkSheetStatus.DRAFT) {
    eventsColumns.push({
      key: 'options',
      title: t('edit.events.options.title'),
      dataIndex: 'eventId',
      width: '30%',
      render: (id, record) => (
        <Flex gap={12}>
          {editingEventId === record.id ? (
            <>
              <Tooltip title={t('edit.expenses.actions.popconfirm.cancel')}>
                <Button
                  onClick={() => {
                    setEditingEventId('');
                  }}
                >
                  <CloseOutlined />
                </Button>
              </Tooltip>
              <Tooltip title={t('edit.expenses.actions.popconfirm.ok')}>
                <Button
                  loading={loading}
                  onClick={() => updateEvent(editingEventId)}
                  disabled={!editData?.duration || !editData?.eventType}
                >
                  <CheckOutlined />
                </Button>
              </Tooltip>
            </>
          ) : (
            <>
              <Tooltip title={t('edit.events.options.edit_event')}>
                <Button
                  disabled={record.overlapEvent}
                  onClick={() => {
                    setEditingEventId(record.id);
                    setEditData((prevData) => ({
                      ...prevData,
                      dateStart: record.event.dateStart,
                      dateEnd: record.event.dateEnd,
                    }));
                  }}
                >
                  <EditOutlined />
                </Button>
              </Tooltip>
              <Popconfirm
                title={t('edit.events.options.popconfirm.split')}
                onConfirm={() => splitEvent(record.id)}
                okText={t('edit.events.options.popconfirm.ok')}
                cancelText={t('edit.events.options.popconfirm.cancel')}
              >
                <Button disabled={record.overlapEvent}>
                  <PlusOutlined />
                </Button>
              </Popconfirm>
            </>
          )}
          <Popconfirm
            title={t('edit.events.options.popconfirm.delete')}
            onConfirm={() => deleteEvent(record.id)}
            okText={t('edit.events.options.popconfirm.ok')}
            cancelText={t('edit.events.options.popconfirm.cancel')}
          >
            <Button loading={loading} danger disabled={record.overlapEvent}>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </Flex>
      ),
    });
  }

  return (
    <div ref={tableRef}>
      <h4>{t('edit.events.title')}</h4>
      <Table
        rowKey={(record) => record.id}
        loading={loading}
        dataSource={events}
        scroll={{
          x: 800,
        }}
        columns={eventsColumns}
        pagination={{
          hideOnSinglePage: true,
          total: events.length,
          pageSize: 10,
          onChange: () => {
            if (tableRef.current) {
              tableRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
              });
            }
          },
        }}
        rowClassName={(record) => {
          const classes = [];

          if (record.overlapEvent) {
            classes.push('double-wsEvent-row');
          }

          if (record.splitEvent) {
            classes.push('split-wsEvent-row');
          }

          return classes.join(' ');
        }}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('common:no_data')}
            />
          ),
        }}
      />
    </div>
  );
};

export default WorksheetEvents;
